import style from './common.module.css'
import { ButtonWrapper } from '../../_buttons'
import { ImgBox } from './img-box'
import { TextBox } from './text-box'
import ResumePlaybackProgressBar from '@features/_ui/resume-playback-progress-bar'
import { useAppSelector } from '@root/store'
import { selectContentPropByContentId } from '@store/contents-slice/_selectors'
import { HOME_PAGE_CHANNEL_PATHNAME } from '@shared/constants/root.constants'
import { parseBoolean } from '@utils/connascence'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import { BORDER_FALLBACK } from '@shared/constants/theme/colours.constants'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { returnChannelOrContentPath } from '@utils/custom-pages'
import useFetchAuthBundle from '@hooks/_content/bundle/use-fetch-auth-bundle'
import useBundlePathToUseByLayout from '@hooks/_content/bundle/use-bundle-path-to-use-by-layout'
import { clsx } from 'clsx'

export type Props = {
  contentId: string
  channelPathname: string
  classname?: string
}

export function SecondaryCard({ contentId, classname, channelPathname }: Props): JSX.Element {
  // If the channel pathname = '/' then this is a home page rail.
  // If that is the case then the content will contain the channelUrl property for routing
  // As content is no longer tied to a channel
  const contentChannelUrl = useAppSelector(selectContentPropByContentId(contentId)('channelUrl'))
  channelPathname = returnChannelOrContentPath(channelPathname, contentChannelUrl)

  // THEME
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))
  const cardThemeColours: Record<string, unknown> = {
    '--border': channelTheme?.buttonColour ?? BORDER_FALLBACK,
  }

  useFetchAuthBundle(contentId, channelPathname)
  const contentLink = useBundlePathToUseByLayout(contentId, channelPathname)

  return (
    <ButtonWrapper href={contentLink} className={classname}>
      <div className={clsx(style.wrapper, classname, style.newCardStyle)} style={cardThemeColours}>
        <ImgBox contentId={contentId} />
        <TextBox contentId={contentId} channelPathname={channelPathname} />
        <ResumePlaybackProgressBar
          contentId={contentId}
          channelPathname={channelPathname}
          variant={'secondaryCard'}
        />
      </div>
    </ButtonWrapper>
  )
}

import ImgBox from './img-box'
import TextButtonBox from './text-button-box'
import style from './common.module.css'
import primaryStyle from './primary.module.css'
import { ButtonWrapper } from '../../_buttons'
import { useAppSelector } from '@root/store'
import { selectContentById, selectContentPropByContentId } from '@store/contents-slice/_selectors'
import {
  DEFAULT_AUTOPLAY_QUERY,
  HOME_PAGE_CHANNEL_PATHNAME,
} from '@shared/constants/root.constants'
import useGetBundleLink from '@hooks/bundle-watch-hooks/use-get-bundle-link'
import { parseBoolean } from '@utils/connascence'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import { BORDER_FALLBACK } from '@shared/constants/theme/colours.constants'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { returnChannelOrContentPath } from '@utils/custom-pages'
import useFetchAuthBundle from '@hooks/_content/bundle/use-fetch-auth-bundle'
import { clsx } from 'clsx'
import useBoughtPath from '@hooks/_content/bundle/use-bought-path'
import { useMemo } from 'react'
import { ContentLayout } from '@shared/enums/content/content-layout.enum'
import { useCheckoutVideoFlowState } from '@library/_flows/_checkout-video-flow/_hooks/use-checkout-video-flow-state'
import { generateContentPath } from '@utils/content/generate-content-path'
import useBundlePathToUseByLayout from '@hooks/_content/bundle/use-bundle-path-to-use-by-layout'

export type Props = {
  contentId: string
  parity?: 'even' | 'odd'
  channelPathname: string
  isShowChannel?: boolean
}

export function PrimaryCard({
  contentId,
  parity = 'even',
  isShowChannel,
  channelPathname,
}: Props): JSX.Element {
  // If the channel pathname = '/' then this is a home page rail.
  // If that is the case then the content will contain the channelUrl property for routing
  // As content is no longer tied to a channel
  const contentChannelUrl = useAppSelector(selectContentPropByContentId(contentId)('channelUrl'))
  channelPathname = returnChannelOrContentPath(channelPathname, contentChannelUrl)

  useFetchAuthBundle(contentId, channelPathname)
  const purchasedLink = useBundlePathToUseByLayout(contentId, channelPathname)

  // THEME
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))
  const cardThemeColours: Record<string, unknown> = {
    '--border': channelTheme?.buttonColour ?? BORDER_FALLBACK,
  }

  return (
    <ButtonWrapper href={purchasedLink ?? `/${channelPathname}/${contentId}`}>
      <div
        className={clsx(style.wrapper, primaryStyle.wrapper, style.newCardStyle)}
        style={cardThemeColours}
      >
        <ImgBox contentId={contentId} parity={parity} channelPathname={channelPathname} />
        <TextButtonBox
          contentId={contentId}
          channelPathname={channelPathname}
          isShowChannel={isShowChannel}
        />
      </div>
    </ButtonWrapper>
  )
}

import useBoughtPath from '@hooks/_content/bundle/use-bought-path'
import { useMemo } from 'react'
import { ContentLayout } from '@shared/enums/content/content-layout.enum'
import { useSelector } from 'react-redux'
import { selectContentById, selectContentPropByContentId } from '@store/contents-slice/_selectors'
import { useCheckoutVideoFlowState } from '@library/_flows/_checkout-video-flow/_hooks/use-checkout-video-flow-state'
import useGetBundleLinkNoFetch from '@hooks/bundle-watch-hooks/use-get-bundle-link-no-fetch'
import { isEmptyString } from '@utils/strings'
import { generateContentPath } from '@utils/content/generate-content-path'
import { useAppSelector } from '@root/store'

export default function useBundlePathToUseByLayout(contentId: string, channelUrl: string) {
  const bundle = useAppSelector(selectContentPropByContentId(contentId)('bundle'))
  const state = useAppSelector(selectContentPropByContentId(contentId)('state'))
  const content = useSelector(selectContentById(contentId))
  const checkoutVideoFlowState = useCheckoutVideoFlowState(contentId)
  const purchasedLinkLegacy = useGetBundleLinkNoFetch({ contentId, channelPathname: channelUrl })
  const purchasedLinkModem = useBoughtPath(contentId, channelUrl)

  return useMemo(() => {
    const contentLayout = content?.contentLayoutStyle ?? ContentLayout.MODERN
    const hasModernLayout = contentLayout === ContentLayout.MODERN
    const bundleSubType = bundle?.bundleType?.typeName
    const watchStatus = ['purchased', 'watchNow']
    const isPurchased = watchStatus.includes(state.button)

    if (content.contentType === 'bundle') {
      if (isPurchased && hasModernLayout && bundleSubType === 'series') {
        return purchasedLinkModem
      } else if (!!purchasedLinkLegacy && !isEmptyString(purchasedLinkLegacy)) {
        return purchasedLinkLegacy
      }
    } else {
      return generateContentPath(contentId, channelUrl, isPurchased)
    }
  }, [
    channelUrl,
    checkoutVideoFlowState,
    content,
    contentId,
    purchasedLinkLegacy,
    purchasedLinkModem,
  ])
}

export const CONTENT_NAME_PLACEHOLDER = 'Untitled content'

// Prices placeholders
export const PRICE_TITLE = 'Price'
export const PRICES_FROM = 'From '
export const BUY_NOW = 'Buy now'
export const FREE = 'Free'
export const PAY = 'Pay'
export const CONTENT_NOT_AVAILABLE = 'Content is not available.'
export const CONTENT_EXPIRED = 'Content expired'
export const CONTENT_EXPIRED_MESSAGE = 'This stream is no longer available'
export const SUBSCRIBE = 'Subscribe'
export const NOT_FOR_INDIVIDUAL_SALES = 'Content not available'
export const NOT_FOR_INDIVIDUAL_SALES_MESSAGE =
  'This content is not available for individual purchase.'

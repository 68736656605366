import { selectContentById, selectContentPropByContentId } from '@store/contents-slice/_selectors'
import { useSelector } from 'react-redux'
import ChannelOwnerRail from '@ui/rails/channel-owner'
import styles from './styles.module.scss'
import RailContentArtwork from '@ui/rails/content-artwork'
import { CONTENT_NAME_PLACEHOLDER } from '@shared/constants/content/placeholders.constants'
import useContentDescription from '@hooks/_content/use-content-description'
import DOMPurify from 'dompurify'
import { HOME_PAGE_CHANNEL_PATHNAME } from '@shared/constants/root.constants'
import { ToVideoInfoButton } from '@library/_flows/_checkout-video-flow/to-video-info-button'
import RenderIf from '@utils/render-if'
import { useAppSelector, useAppSelector as useSel } from '@root/store'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import ContentSchedule from '@ui/content-schedule'
import { BORDER_FALLBACK } from '@shared/constants/theme/colours.constants'
import { useEffect, useMemo, useRef, useState } from 'react'
import { MAX_CHARS_IN_LONG_TITLE_BY_CHARS } from '@shared/constants/rails.constants'
import { SizesEnum } from '@shared/enums/layout/sizes.enum'
import Link from 'next/link'
import { useRouter } from 'next/router'
import VodAudioDurationBadge from '@ui/duration-badge/vod-audio'
import { generateContentPath } from '@utils/content/generate-content-path'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { isCustomPagePath, returnChannelOrContentPath } from '@utils/custom-pages'
import { ContentLayout } from '@shared/enums/content/content-layout.enum'
import { useCheckoutVideoFlowState } from '@library/_flows/_checkout-video-flow/_hooks/use-checkout-video-flow-state'
import useBundle from 'hooks/_content/bundle/use-fetch-auth-bundle'
import useFetchAuthBundle from 'hooks/_content/bundle/use-fetch-auth-bundle'
import useBoughtPath from '@hooks/_content/bundle/use-bought-path'
import useBundlePathToUseByLayout from '@hooks/_content/bundle/use-bundle-path-to-use-by-layout'
import { isDefined } from '@utils/connascence'

export interface Props {
  contentId: string
  channelPathname: string
}

export default function RailCard({ contentId, channelPathname }: Props): JSX.Element {
  const router = useRouter()
  const content = useSelector(selectContentById(contentId))
  const title = content?.title ?? CONTENT_NAME_PLACEHOLDER
  const paragraph = useContentDescription(contentId)
  const _paragraph = DOMPurify.sanitize(paragraph)
  const [descSize, setDescSize] = useState<SizesEnum>(SizesEnum.SMALL)
  const [isScheduleEmpty, setScheduleEmpty] = useState<boolean>(false)
  // THEME
  const channelTheme = useSel(selectChannelThemeByChannelPathname(channelPathname))

  // Content channel
  const contentChannelUrl = useAppSelector(selectContentPropByContentId(contentId)('channelUrl'))

  const showChannelLogo = isCustomPagePath(channelPathname)
  channelPathname = returnChannelOrContentPath(channelPathname, contentChannelUrl)

  const cardThemeColours: Record<string, unknown> = {
    '--border': channelTheme?.buttonColour ?? BORDER_FALLBACK,
  }

  // Determinate how many lines we can display in the description
  useMemo(() => {
    const isLongTitle = title?.length > MAX_CHARS_IN_LONG_TITLE_BY_CHARS
    if (isLongTitle && isScheduleEmpty) {
      setDescSize(SizesEnum.SMALL)
    } else if ((isLongTitle && !isScheduleEmpty) || (!isLongTitle && isScheduleEmpty)) {
      setDescSize(SizesEnum.MEDIUM)
    } else {
      setDescSize(SizesEnum.LARGE)
    }
  }, [isScheduleEmpty, title])

  // Bundles
  useFetchAuthBundle(contentId, channelPathname)

  const contentLink = useBundlePathToUseByLayout(contentId, channelPathname)

  useEffect(() => {
    if (router.isReady) {
      router.prefetch(contentLink)
    }
  }, [contentLink, router])

  return (
    <Link href={contentLink}>
      <a
        className={` ${styles.cardRail} ${showChannelLogo ? styles.withChannel : styles.noChannel}`}
        style={cardThemeColours}
      >
        <RenderIf isTrue={showChannelLogo}>
          <ChannelOwnerRail channelPathname={channelPathname} />
        </RenderIf>
        <div className={styles.artworkWrapper}>
          <RailContentArtwork contentId={contentId} />
          <div className={styles.duration}>
            <VodAudioDurationBadge contentId={contentId} size={'small'} />
          </div>
        </div>
        <div className={styles.info}>
          <h5>{title}</h5>
          <ContentSchedule
            contentId={contentId}
            className={styles.schedule}
            callback={setScheduleEmpty}
          />
          <div className={`${styles.desc} ${styles[descSize]}`}>
            <p
              className={`${showChannelLogo ? styles.withChannel : ''}`}
              dangerouslySetInnerHTML={{ __html: _paragraph }}
            />
          </div>
        </div>
        <div className={styles.button}>
          <ToVideoInfoButton
            contentId={contentId}
            channelPathname={channelPathname}
            isFullWidth={false}
          />
        </div>
      </a>
    </Link>
  )
}

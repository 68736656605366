import { useAppSelector, useAppSelector as useSel } from '@root/store'
import { selectContentById, selectContentPropByContentId } from '@store/contents-slice/_selectors'
import { useCallback, useEffect, useRef } from 'react'
import { getContentByIdThunk, getContentResourcesByIdThunk } from '@store/contents-slice/_thunks'
import {
  selectChannelData,
  selectChannelPropByChannelPathname,
} from '@store/channel-slice/_selectors'
import { useDispatch } from 'react-redux'
import { selectUserId } from '@store/user-slice/_selectors'
import { isDefined } from '@utils/connascence'
import { isEmptyString } from '@utils/strings'
import { PurchaseDetails } from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'
import { selectUserBillingPurchases } from '@store/user-billing-slice/purchases/_selectors'
import useUserHasAccessToContent from '@hooks/_content/use-user-has-access-to-content'

export default function useFetchAuthBundle(contentId: string, channelUrl: string) {
  const dispatch = useDispatch()

  // Selectors
  const content = useSel(selectContentById(contentId))
  const channelId = useSel(selectChannelPropByChannelPathname(channelUrl)('channelId'))
  const hasFetchContent = useRef(false)
  const userId = useSel(selectUserId)
  const userHasAccess = useUserHasAccessToContent(contentId)

  const fetchBundleData = useCallback(() => {
    if (isDefined(channelId) && !isEmptyString(channelId) && userHasAccess) {
      dispatch(getContentByIdThunk({ contentId, channelId }))
      dispatch(getContentResourcesByIdThunk(contentId))
    }
  }, [channelId, contentId, dispatch, userHasAccess])

  /**
   * Fetching Bundle data
   * If the contentType is bundle, AND there is an active session,
   * AND the user has access to the bundle we reFetch bundle data. To get all the bundle information
   */
  useEffect(() => {
    if (!hasFetchContent.current && userHasAccess) {
      const contentType = content?.contentType
      if (contentType === 'bundle' && channelId && userId) {
        fetchBundleData()
        hasFetchContent.current = true
      }
    }
  }, [userId, contentId, channelId, dispatch, content, fetchBundleData, userHasAccess])

  // Fetch content, if it doesn't exist into the state. This is the first load before firebase
  useEffect(() => {
    if (!isDefined(content) && isDefined(channelId)) {
      fetchBundleData()
    }
  }, [channelId, content])

  return {
    fetchBundleData,
  }
}
